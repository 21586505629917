import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeValue } from '@angular/platform-browser';

@Pipe({
	name: 'backgroundUrl'
})
export class BackgroundUrlPipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {

	}

	transform(value: string): SafeValue {
		return this.sanitizer.bypassSecurityTrustStyle(`url(${value})`);
	}

}
