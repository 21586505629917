<div class='well'>
	<ng-container *ngFor='let row of board'>
		<ng-container *ngFor='let col of row'>
			<div
				class='box'
				[class.box--visible]='col'
			></div>
		</ng-container>
	</ng-container>

	<t-piece [piece]='currentPiece'></t-piece>
	<t-piece
		*ngFor="let p of prevPieces"
		[piece]='p'
	></t-piece>
</div>
<div class='controls'>
	timer<br>
	{{timer$ | async}}
</div>
<div class='buttons'>
	<div>
		<button
			class='btn'
			(mousedown)='move(Direction.Left)'
		>
			<t-icon icon='arrow-left'></t-icon>
		</button>

		<button
			class='btn'
			(mousedown)='move(Direction.Right)'
		>
			<t-icon icon='arrow-right'></t-icon>
		</button>
	</div>
	<button
		class='btn'
		(mousedown)='rotate()'
	>
		<t-icon icon='rotate'></t-icon>
	</button>

</div>
