import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 't-fail',
  templateUrl: './fail.component.html',
  styleUrls: ['./fail.component.styl'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
