import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GameComponent } from '@pages/game/game.component';
import { HomeComponent } from '@pages/home/home.component';
import { FailComponent } from '@pages/fail/fail.component';
import { WinComponent } from '@pages/win/win.component';

const routes: Routes = [{
	path: '',
	component: HomeComponent
}, {
	path: 'game',
	component: GameComponent
}, {
	path: 'fail',
	component: FailComponent
}, {
	path: 'win',
	component: WinComponent
}];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
