<div
	class='piece'
	*ngIf='piece'
	[style.top.px]='piece.y * 34'
	[style.left.px]='piece.x * 34'
>
	<ng-container *ngFor='let row of piece.shape'>
		<div
			class='box piece__box'
			*ngFor='let i of row'
			[class.piece__box--visible]='i'
		></div>
	</ng-container>

	<div
		class='piece__bg'
		[style.background-image]='piece.image | backgroundUrl'
		[style.transform]='" rotate(" + piece.rotation + "deg) " + piece.params.transform  '
	>
	</div>

	<!--
			[style.transform]='"rotate(" + piece.rotation + "deg)"'-->
</div>
