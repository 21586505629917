import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 't-piece',
	templateUrl: './piece.component.html',
	styleUrls: ['./piece.component.styl'],
	// changeDetection: ChangeDetectionStrategy.OnPush
})
export class PieceComponent {
	@Input() piece;
}
