export class Share {
	static vkontakte(purl, ptitle, pimg?, text?) {
		let url = 'http://vkontakte.ru/share.php?';
		url += 'url=' + encodeURIComponent(purl);
		url += '&title=' + encodeURIComponent(ptitle);
		if (pimg) {
			url += '&image=' + encodeURIComponent(pimg);
		}
		if (text) {
			url += '&description=' + encodeURIComponent(text);
		}
		url += '&noparse=true';
		Share.popup(url);
	}

	static odnoklassniki(purl, text) {
		let url = 'http://www.odnoklassniki.ru/dk?st.cmd=addShare&st.s=1';
		url += '&st.comments=' + encodeURIComponent(text);
		url += '&st._surl=' + encodeURIComponent(purl);
		Share.popup(url);
	}

	static facebook(purl, ptitle, pimg?, text?) {
		let url = 'http://www.facebook.com/sharer.php?s=100';
		url += '&p[title]=' + encodeURIComponent(ptitle);
		url += '&p[url]=' + encodeURIComponent(purl);
		if (pimg) {
			url += '&p[images][0]=' + encodeURIComponent(pimg);
		}
		if (text) {
			url += '&p[summary]=' + encodeURIComponent(text);
		}
		Share.popup(url);
	}

	static twitter(purl, ptitle) {
		let url = 'http://twitter.com/share?';
		url += 'text=' + encodeURIComponent(ptitle);
		url += '&url=' + encodeURIComponent(purl);
		url += '&counturl=' + encodeURIComponent(purl);
		Share.popup(url);
	}

	static popup(url) {
		window.open(url, '', 'toolbar=0,status=0,width=626,height=436');
	}
}
