import { Component, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import innerHeight from 'ios-inner-height';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2/gst';
import { isPlatformServer } from '@angular/common';

@Component({
	selector: 't-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.styl']
})
export class AppComponent {
	constructor(
		angulartics: Angulartics2GoogleGlobalSiteTag,
		@Inject(PLATFORM_ID) private platformId
	) {
		angulartics.startTracking();
		this.onResize();
	}

	@HostListener('window:resize')
	onResize() {
		if (isPlatformServer(this.platformId)) {
			return;
		}

		document.documentElement.style.setProperty('--window-height', innerHeight() + 'px');
	}
}
