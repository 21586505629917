import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';

@Injectable({
	providedIn: 'root'
})
export class SoundService {

	constructor(@Inject(PLATFORM_ID) private platformId) {
		if (isPlatformServer(this.platformId)) {
			return;
		}

		const sound = new Audio('/assets/tetris.mp3');
		sound.loop = true;
		sound.play();
	}
}
