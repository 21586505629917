import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 't-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.styl'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent {
}
