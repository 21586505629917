import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SoundService } from '@services/sound.service';

const Width = 8;
const Height = 12;

const TetrominoSize = 4;

const Tetrominos = [
	[
		[0, 0, 0, 0],
		[1, 1, 1, 1],
		[0, 0, 0, 0],
		[0, 0, 0, 0],
	], [
		[0, 0, 0, 0],
		[0, 0, 1, 0],
		[0, 0, 1, 0],
		[0, 1, 1, 0],
	], [
		[0, 0, 0, 0],
		[0, 1, 0, 0],
		[0, 1, 0, 0],
		[0, 1, 1, 0],
	], [
		[0, 0, 0, 0],
		[0, 1, 1, 0],
		[0, 1, 1, 0],
		[0, 0, 0, 0],
	], [
		[0, 0, 0, 0],
		[0, 1, 1, 0],
		[1, 1, 0, 0],
		[0, 0, 0, 0],
	], [
		[0, 0, 0, 0],
		[1, 1, 1, 0],
		[0, 1, 0, 0],
		[0, 0, 0, 0],
	], [
		[0, 0, 0, 0],
		[1, 1, 0, 0],
		[0, 1, 1, 0],
		[0, 0, 0, 0],
	]
];

// const randomPiece = (): Array<Array<number>> => {
// 	return Tetrominos[Math.floor(Math.random() * Tetrominos.length)];
// };

enum Direction {
	Left,
	Right,
	Bottom,
	None
}

const getDirectionVector = (direction: Direction): Array<number> => {
	switch (direction) {
		case Direction.Bottom:
			return [0, 1];
		case Direction.Left:
			return [-1, 0];
		case Direction.Right:
			return [1, 0];
		case Direction.None:
			return [0, 0];
	}
};

const TetraminoParams = [
	{ letter: 'i', transform: `translate3d(0, 34px, 0)` },
	{ letter: 'j', transform: `translate3d(34px, 0px, 0) rotate(-90deg)` },
	{ letter: 'j', transform: `scaleX(-1) translate3d(34px, 0px, 0) rotate(-90deg) ` },
	{ letter: 'o', transform: `translate3d(34px, 34px, 0)` },
	{ letter: 'z', transform: `translate3d(0, 34px, 0)` },
	{ letter: 't', transform: `translate3d(-34px, -34px, 0) rotate(180deg)` },
	{ letter: 'z', transform: `scaleY(-1) translate3d(0, 34px, 0)` },
];

const sumBoard = (board: Array<Array<number>>): number => {
	let sum = 0;

	board.forEach(row => row.forEach(col => sum += col));
	return sum;
};

const mergeBoards = (a: Array<Array<number>>, b: Array<Array<number>>): Array<Array<number>> => {
	const board = getEmptyBoard();

	board.forEach((r, ri) => {
		r.forEach((c, ci) => {
			board[ri][ci] = a[ri][ci] || b[ri][ci];
		});
	});

	return board;
};

const copy = (o: any): any => JSON.parse(JSON.stringify(o));
const copyClass = orig => Object.assign(Object.create(Object.getPrototypeOf(orig)), orig)


class Piece {
	x = 0;
	y = 0;
	rotation = 0;
	shape: Array<Array<number>>;

	image;
	params;

	constructor() {
		const index = Math.floor(Math.random() * Tetrominos.length);
		const imageIndex = 1 + Math.floor(Math.random() * 7);

		this.shape = copy(Tetrominos[index]);
		this.params = TetraminoParams[index];
		this.image = `/assets/images/${this.params.letter}/${imageIndex}.png`;
	}

	addMove(direction: Direction): Array<Array<number>> {
		const board = getEmptyBoard();
		const vector = getDirectionVector(direction);

		this.shape.forEach((row, ri) => {
			row.forEach((s, ci) => {
				const x = this.x + vector[0] + ci;
				const y = this.y + vector[1] + ri;
				if (y > Height - 1 || x > Width - 1 || x < 0 || y < 0 || !s) {
					return;
				}
				board[y][x] = board[y][x] || s;
			});
		});

		return board;
	}

	applyToBoard(board: Array<Array<number>>) {
		this.shape.forEach((row, ri) => {
			row.forEach((s, ci) => {
				const x = this.x + ci;
				const y = this.y + ri;

				if (y > Height - 1 || x > Width - 1 || x < 0 || y < 0) {
					return;
				}
				board[y][x] = board[y][x] || s;
			});
		});
	}

	isValidMove(direction: Direction, board: Array<Array<number>>): boolean {
		return sumBoard(mergeBoards(this.addMove(direction), board)) - sumBoard(board) === 4;
	}

	getBB() {
		return {
			top: this.shape.findIndex(row => row.some(Boolean)),
			left: Math.min(...this.shape.map(row => row.findIndex(Boolean)).filter(r => r !== -1)),
			right: 3 - Math.max(...this.shape.map(row => row.map(Boolean).lastIndexOf(true)).filter(r => r !== -1)),
		};
	}
}

const getEmptyBoard = () => Array.from(
	{ length: Height }, () => Array(Width).fill(0)
);

const rotate = (arr: Array<Array<number>>): Array<Array<number>> => {
	let copied = copy(arr);
	copied = copied.reverse();

	for (let i = 0; i < copied.length; i++) {
		for (let j = 0; j < i; j++) {
			const temp = copied[i][j];
			copied[i][j] = copied[j][i];
			copied[j][i] = temp;
		}
	}

	return copied;
};

const GameTime = 59;

@Component({
	selector: 't-game',
	templateUrl: './game.component.html',
	styleUrls: ['./game.component.styl'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GameComponent implements OnDestroy {
	board: Array<Array<number>>;
	prevPieces: Array<Piece> = [];
	currentPiece: Piece;

	Direction = Direction;

	timer$;

	private destroy$ = new Subject();

	constructor(
		private cdr: ChangeDetectorRef,
		private router: Router,
		private soundService: SoundService
	) {
		this.generateBoard();
		this.spawnNext();

		this.runTimer();

		timer(0, 500)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => this.tick());
	}

	runTimer() {
		this.timer$ = timer(0, 1000)
			.pipe(
				map(v => GameTime - v),
				tap(v => {
					if (v === 0) {
						this.router.navigateByUrl('/win');
					}
				}),
				map(v => `00:${v.toString().padStart(2, '0')}`),
				take(GameTime + 1),
				takeUntil(this.destroy$)
			);
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private generateBoard() {
		this.board = getEmptyBoard();
	}

	private spawnNext() {
		const piece = new Piece();

		const bb = piece.getBB();
		piece.y = -bb.top;
		piece.x = Width / 2 - 2;

		const canSpawn = piece.isValidMove(Direction.None, this.board);

		if (!canSpawn) {
			this.router.navigateByUrl('/fail');
		}

		if (this.currentPiece) {
			this.prevPieces.push(this.currentPiece);
		}
		this.currentPiece = piece;
	}

	private tick() {
		const b = this.currentPiece.isValidMove(Direction.Bottom, this.board);

		if (b) {
			this.currentPiece.y++;
		} else {
			this.currentPiece.applyToBoard(this.board);
			this.spawnNext();
		}

		this.cdr.markForCheck();
	}

	move(direction: Direction) {
		if (this.currentPiece.isValidMove(direction, this.board)) {
			const vector = getDirectionVector(direction);
			this.currentPiece.x += vector[0];
			this.currentPiece.y += vector[1];
			this.cdr.markForCheck();
		}
	}

	rotate() {
		const newPiece = copyClass(this.currentPiece);
		newPiece.shape = rotate(newPiece.shape);
		newPiece.rotation += 90;

		if (newPiece.isValidMove(Direction.None, this.board)) {
			this.currentPiece = newPiece;
		} else {
			return;
		}
		this.cdr.markForCheck();
	}
}
