<h1>Получилось!</h1>
<p>В&nbsp;награду мы&nbsp;поможем планете и&nbsp;посадим на&nbsp;твой выбор:</p>

<button
	class='select'
	(click)='select(1)'
	[class.active]='isSelected(1)'
	angulartics2On="click"
	angularticsAction="AppleSelect"
>
	<span class='select__image'>
		<img
			src='/assets/images/apple.svg'
			alt=''
		>
	</span>
	<span class='select__text'>Яблоня</span>
</button>
<button
	class='select'
	(click)='select(2)'
	[class.active]='isSelected(2)'
	angulartics2On="click"
	angularticsAction="CedarSelect"
>
	<span class='select__image'>
		<img
			src='/assets/images/kedr.svg'
			alt=''
		>
	</span>
	<span class='select__text'>Кедр</span>
</button>

<p>А&nbsp;ты&nbsp;в&nbsp;свою очередь выбери электронные чеки, вместо бумажных.</p>
<div class='logos'>
	<a
		href='https://vkusvill.ru'
		target='_blank'
		rel='noopener noreferrer'
	>
		<img
			src='/assets/images/logo1.svg'
			alt=''
		>
	</a>
	<a
		href='https://av.ru'
		target='_blank'
		rel='noopener noreferrer'
	>
		<img
			src='/assets/images/logo2.svg'
			alt=''
		>
	</a>
</div>
<p>И&nbsp;расскажи об&nbsp;этой проблеме друзьям!</p>
<button
	class='btn'
	(click)='share()'
	*ngIf='hasShare'
>Поделиться
</button>

<div
	class='share'
	*ngIf='!hasShare'
>
	<button
		class='btn'
		(click)='vk()'
	>
		<t-icon icon='vk'></t-icon>
	</button>
	<button
		class='btn'
		(click)='facebook()'
	>
		<t-icon icon='fb'></t-icon>
	</button>
</div>
