import { ChangeDetectionStrategy, Component, Inject, PLATFORM_ID } from '@angular/core';
import { Share } from '@pages/win/share';
import { isPlatformServer } from '@angular/common';

@Component({
	selector: 't-win',
	templateUrl: './win.component.html',
	styleUrls: ['./win.component.styl'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class WinComponent {
	selected = 0;

	constructor(@Inject(PLATFORM_ID) private platformId) {
	}

	get hasShare(): boolean {
		if (isPlatformServer(this.platformId)) {
			return false;
		}

		return Boolean(navigator.share);
	}

	share() {
		navigator.share({
			text: `Я узнал(а) о проблеме бумажных чеков и посадил ${this.selected === 1 ? 'яблоню' : 'кедр'}, сыграв в игру https://humantetris.ecoberg.ru/`,
			url: 'https://humantetris.ecoberg.ru/',
		});
	}

	vk() {
		Share.vkontakte('https://humantetris.ecoberg.ru/', `Я узнал(а) о проблеме бумажных чеков и посадил ${this.selected === 1 ? 'яблоню' : 'кедр'}, сыграв в игру https://humantetris.ecoberg.ru/`);
	}

	facebook() {
		Share.facebook('https://humantetris.ecoberg.ru/', `Я узнал(а) о проблеме бумажных чеков и посадил ${this.selected === 1 ? 'яблоню' : 'кедр'}, сыграв в игру https://humantetris.ecoberg.ru/`);
	}

	select(num: number) {
		this.selected = num;
	}

	isSelected(num: number): boolean {
		return this.selected === num;
	}
}
