import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GameComponent } from '@pages/game/game.component';
import { BackgroundUrlPipe } from './background-url.pipe';
import { PieceComponent } from '@pages/game/piece/piece.component';
import { HomeComponent } from '@pages/home/home.component';
import { FailComponent } from '@pages/fail/fail.component';
import { WinComponent } from '@pages/win/win.component';
import { IconComponent } from '@components/icon/icon.component';


import { Angulartics2Module } from 'angulartics2';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2/gst';

@NgModule({
	declarations: [
		AppComponent,
		GameComponent,
		BackgroundUrlPipe,
		PieceComponent,
		HomeComponent,
		FailComponent,
		WinComponent,
		IconComponent
	],
	imports: [
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		AppRoutingModule,
		Angulartics2Module.forRoot()
	],
	providers: [],
	bootstrap: [AppComponent]
})
export class AppModule {
}
